import React from 'react'
import ReactWOW from 'react-wow'
import { Link } from 'gatsby'
import wispeek from '../../assets/images/home/wispeek.png'
import { AnchorLink } from "gatsby-plugin-anchor-links"

// // Logos partenaires
// import partner2 from '../../assets/images/partner/pcyan.png'
// import partner8 from '../../assets/images/partner/porange.png'
// import partner9 from '../../assets/images/partner/pred.png'
// import partner12 from '../../assets/images/partner/pblack.png'
// import partner14 from '../../assets/images/partner/pblue.png'


const Banner = () => {
    return (
        <div className="it-services-banner" style={{marginBottom:'50px'}}>
            <div className="container">
                <div className="row align-items-center pt-30">
                    <div className='col-lg-12 col-md-12 center'>
                        <h1 className='title-home' style={{fontSize:'2rem'}}>
                            Dispositif de signalement<br></br>
                            et de remontées d'informations
                        </h1>
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                            <div className="project-start-image pt-30">
                                <img width='50%' src={wispeek} alt="wispeek mutli-devices" />
                            </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-6 col-md-12 center pt-10">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInRight'>
                                <div className="btn-box">
                                    <p>Vous êtes salarié, collaborateur ?</p>
                                    <AnchorLink to="/#salaries" className="default-btn">
                                        <i className="bx bx-chevron-right"></i> 
                                        Mon espace wispeek <span></span>
                                    </AnchorLink>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12 center pt-10">
                        <div className="banner-wrapper-content">
                            <ReactWOW delay='.1s' animation='fadeInRight'> 
                                <div className="btn-box">
                                    <p>Vous êtes référent, responsable hiérarchique ?</p>
                                    <Link to="/referents" className="default-btn btn-custom">
                                        <i className="bx bx-chevron-right"></i> 
                                        Espace référent <span></span>
                                    </Link>
                                </div>
                            </ReactWOW>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner